import React from "react";
import { useInfoValue } from "../Contexts/InfoContext";
import logo from "./logo.png";
import banner from "./banner.jpg";

export const Template = () => {
    const { data, globalData } = useInfoValue();
    return (
        <>
            <table
                border='0'
                cellPadding='0'
                cellSpacing='0'
                width='100%'
                style={{ margin: "0 0 20px" }}
            >
                <tr>
                    <td>
                        <p
                            style={{
                                margin: 0,
                                fontFamily: "Arial",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: 1.4,
                                color: "#000066",
                            }}
                        >
                            {data.title ? data.title + " " : ""}
                            {data.firstName} {data.lastName}
                        </p>
                        <p
                            style={{
                                margin: 0,
                                marginBottom: "5px",
                                fontFamily: "Arial",
                                fontWeight: 300,
                                fontSize: "12px",
                                lineHeight: 1.5,
                                color: "#00CCCC",
                            }}
                        >
                            {data.workPosition}
                        </p>
                        <p
                            style={{
                                margin: 0,
                                fontFamily: "Arial",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: 1.4,
                                color: "#000066",
                            }}
                        >
                            <a
                                style={{
                                    textDecoration: "unset",
                                    color: "#041547",
                                }}
                                href={`tel:${
                                    data.phoneNumber
                                        ? data.phoneNumber.replace(/ /g, "")
                                        : ""
                                }`}
                            >
                                {data.phoneNumber}
                            </a>
                        </p>
                        <p
                            style={{
                                margin: 0,
                                fontFamily: "Arial",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: 1.4,
                                color: "#000066",
                            }}
                        >
                            <a
                                style={{
                                    textDecoration: "unset",
                                    color: "#041547",
                                }}
                                href={`mailto:${data.email}`}
                            >
                                {data.email}
                            </a>
                        </p>
                    </td>
                </tr>
            </table>
            <table
                border='0'
                cellpadding='0'
                cellspacing='0'
                width='auto'
                style={{
                    marginBottom:
                        globalData.website ||
                        globalData.facebook ||
                        globalData.instagram
                            ? "20px"
                            : "",
                }}
            >
                <tr>
                    {globalData.website && (
                        <td width='44'>
                            <a href={globalData.website}>
                                <img
                                    src={
                                        "https://podpis.finlog.sk/images/web.png"
                                    }
                                    alt='facebook.com/finlog'
                                    width='38'
                                />
                            </a>
                        </td>
                    )}
                    {globalData.facebook && (
                        <td width='44'>
                            <a href={globalData.facebook}>
                                <img
                                    src={
                                        "https://podpis.finlog.sk/images/facebook.png"
                                    }
                                    alt='facebook.com/finlog'
                                    width='38'
                                />
                            </a>
                        </td>
                    )}
                    {globalData.instagram && (
                        <td width='44'>
                            <a href={globalData.instagram}>
                                <img
                                    src={
                                        "https://podpis.finlog.sk/images/instagram.png"
                                    }
                                    alt='facebook.com/finlog'
                                    width='38'
                                />
                            </a>
                        </td>
                    )}
                    {/* <td width='44'>
                        <a href={globalData.youtube}>
						<img
						 src={"https://podpis.finlog.sk/images/youtube.png"}
						alt='facebook.com/finlog'
						width='38'
						/>
                        </a>
                    </td> */}
                </tr>
            </table>

            <table
                border='0'
                cellPadding='0'
                cellSpacing='0'
                width='100%'
                style={{ margin: 0 }}
            >
                <tr>
                    <td width='133'>
                        <img
                            src={`https://www.finlog.sk/signature-logo?v=${Date.now()}`}
                            alt='finlog.sk'
                            width='148'
                        />
                    </td>
                    <td width='37'>
                        <div
                            style={{
                                width: "1px",
                                height: "43px",
                                margin: "0 18px",
                                backgroundColor: "#DFE1EA",
                            }}
                        />
                    </td>
                    <td>
                        <p
                            style={{
                                margin: 0,
                                fontFamily: "Arial",
                                fontWeight: 400,
                                fontSize: "13px",
                                lineHeight: 1.4,
                                color: "#727A93",
                            }}
                        >
                            {data.adress.street}
                            {data.adress.street ? "," : ""}
                            {data.adress.street && <br />}
                            {data.adress.zip} {data.adress.city}
                            {data.adress.zip || data.adress.city ? (
                                <br />
                            ) : (
                                ""
                            )}
                            {data.adress.country}
                        </p>
                    </td>
                </tr>
            </table>
            <table
                border='0'
                cellPadding='0'
                cellSpacing='0'
                width='312'
                style={{ margin: "20px 0 0 0" }}
            >
                {globalData.banner && (
                    <tr>
                        <td>
                            <a
                                href='https://www.finlog.sk/signature-link'
                                target={"_blank"}
                            >
                                <img
                                    src={`https://www.finlog.sk/signature-banner?v=${Date.now()}`}
                                    alt='finlog.sk'
                                    width='312'
                                />
                            </a>
                        </td>
                    </tr>
                )}
                <tr>
                    <td width='312'>
                        <p
                            style={{
                                margin: 0,
                                marginTop: "20px",
                                fontFamily: "Arial",
                                fontWeight: 400,
                                fontSize: "9px",
                                lineHeight: 1.4,
                                color: "#727A93",
                            }}
                        >
                            {globalData.text}
                        </p>
                    </td>
                </tr>
            </table>
        </>
    );
};
